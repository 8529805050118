import React, { useState } from 'react';
import '../Header.css';

const PopupMenu = ({ address, label, onDisconnect, active, switchDisabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleCopyAddress = () => {
        navigator.clipboard.writeText(address);
        setIsOpen(false);
    };

    const handleDisconnect = () => {
        onDisconnect();
        setIsOpen(false);
    };

    // Закрытие меню при клике вне его
    const handleClickOutside = (event) => {
        if (event.target.closest('.popup-menu')) return;
        setIsOpen(false);
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
       

        <div style={{height: "100%"}}>
            
            <span onClick={() => setIsOpen(!isOpen)} className={`wallet-disconnect-menu ${switchDisabled ? "disconnect-menu-disabled" : ""}`} >
                {label} 
            </span>
            {(isOpen && active) && (
                <div className="popup-menu">
                    <div className="popup-menu-item" onClick={handleCopyAddress}>
                        Copy Address
                    </div>
                    <div className="popup-menu-item" onClick={handleDisconnect}>
                        Disconnect
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopupMenu