import { useState } from "react";
import { addVisitedChannel } from "../../utils/checkerConnections";

// Компонент для отображения отдельного элемента подписки
const SubscriptionElement = ({ subscription, isActive, onClick, avatarUrl, readOnly }) => {
    const channel = "https://t.me/" + subscription.substring(1, subscription.length);
   
        

    const handleClick = () => {
        if (isActive || readOnly) {
            return
        }        
        onClick();
    };
    return (
        <>
        {(!readOnly) && (
           
            <a
            className={`form-input collection_subscription channel_link with-square ${isActive ? 'active' : ''}`}
            onClick={handleClick} href={channel} target="_blank" rel="noopener noreferrer"
        >
           
            <img className="collection_subscription_avatar" src={avatarUrl} alt={subscription + " avatar"} />
            <span className="channel_label">
                {subscription}
            </span>
          
        </a>
       
        )
        }

        {readOnly && 

        <div
            className={`form-input collection_subscription channel_link with-square ${isActive ? 'active' : ''} readonly-input`}         
        >
           
            <img className="collection_subscription_avatar" src={avatarUrl} alt={subscription + " avatar"} />
            <span className="channel_label">
                {subscription}
            </span>
          
        </div>

        }

        </>
    );
};

// Компонент для отображения списка подписок
export const SubscriptionList = ({ selectedSubscriptions, setSelectedSubscriptions, subscriptions, avatarUrls, telegramId, readOnly }) => {
  
    // Функция добавления элемента
    const addSubscription = (item) => {
        setSelectedSubscriptions((prevState) => [...prevState, item]); // Добавляет новый элемент в конец массива
    };


    // Обработчик клика
    const handleClick = async (subscription) => {
        const is_add = await addVisitedChannel(telegramId, subscription)
        if (is_add) {
            addSubscription(subscription);
            console.log(selectedSubscriptions)
        }        
    };

    
    return (
        <div>
        
            {subscriptions.map((subscription, index) => (
                        <SubscriptionElement
                            readOnly={readOnly}
                            key={subscription}
                            subscription={subscription}
                            avatarUrl={avatarUrls[index]}
                            isActive={selectedSubscriptions.includes(subscription)}
                            onClick={() => handleClick(subscription)}
                        />
                    ))}
        </div>
    );
};