import { useEffect, useState } from "react";
import DateTimeInput from "./DateTimeInput";
import { getCurrentDate } from "../../utils/appUtils";

export const DateTimeBox = ({ start, end, setStart, setEnd, timeZone }) => {
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);

    useEffect(() => {
        const errors = validateDates()
        setStartError(errors[0]);
        setEndError(errors[1]);
    }, [start, end, timeZone]);


    const validateDates = () => {
       // const now = new Date();
      const now = getCurrentDate(timeZone)
      // console.log("now", now)
        // Учитываем временную зону (timeZone в часах)
        
    
    // Создаем даты с учетом временной зоны
         const startDate = new Date(start);
         //console.log("startDate", startDate)
         const endDate = new Date(end);
        // console.log("endDate", endDate)

        // Проверка даты начала
        const isStartError = startDate <= now;
        

        // Проверка даты конца
        const isEndError = endDate <= now || startDate >= endDate;
     
        return [isStartError, isEndError]
    };

    const handleStartChange = (newStart) => {
        setStart(newStart);
        //validateDates();
    };

    const handleEndChange = (newEnd) => {
        setEnd(newEnd);
        //validateDates();
    };

    return (
        <div className="datetime-inputs-box">
            <div className="form-item">
                <label className="form-item-label">start</label>
                <DateTimeInput
                    input_id="startDate"
                    error={startError}
                    name="start"
                    defaultValue={start || ""}
                    setDateTimeInput={handleStartChange}
                />
            </div>
            <div className="form-item">
                <label className="form-item-label">end</label>
                <DateTimeInput
                    input_id="endDate"
                    error={endError}
                    name="end"
                    defaultValue={end || ""}
                    setDateTimeInput={handleEndChange}
                />
            </div>
        </div>
    );
};