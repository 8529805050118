import { useEffect, useRef, useState } from "react";
import { createNumbersArr } from "../../utils/appUtils";


export function NumberSelector({ setInvite, defaultValue }) {
    const [number, setNumber] = useState(defaultValue);
    const [isOpen, setIsOpen] = useState(false); // Состояние для управления открытым состоянием селектора
    const numbers = Array.from({ length: 32 }, (_, i) => i + 1); // Создаем массив от 1 до 32
   
    const handleChange = (num) => {
        setNumber(num);
        setInvite(num);
        setIsOpen(false); // Закрыть селектор после выбора
    };

    return (
        <div className="form-item margin-bottom-32">
            <label className="form-item-label">Invite</label>
            <div
                className="form-input with-square number-selector margin-bottom-17"
                onClick={() => setIsOpen(!isOpen)}
            >
                {number} {/* Показываем текущее выбранное значение */}
            </div>
            {isOpen && (
                <div style={{display: 'flex'}}> 
                <ul className="options-list number-select">
                    {numbers.map((num) => (
                        <li
                            key={num}
                            className="number-selector-option"
                            onClick={() => handleChange(num)}
                        >
                            {num}
                        </li>
                    ))}
                </ul>
                </div>
            )}
        </div>
    );
}

export const TimeZoneSelector = ({timeZone, setTimeZone}) => {
    const [isOpen, setIsOpen] = useState(false);
    const numbers = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
   


    const numberToStr = (number) => {
        return number < 0 ? number.toString() : number === 0 ? "" : `+${number}`
    }
    const timeZoneFormatted = (number) => {
        const number_str = numberToStr(number)
        const msk_diff_str = numberToStr(number-3)
        return `UTC${number_str} (МСК${msk_diff_str})`
    }
    const handleChange = (num) => {
        setTimeZone(num);
        setIsOpen(false); // Закрыть селектор после выбора
    };

    return (
        <div className="form-item">
            <label className="form-item-label">time zone</label>
            <div
                className="form-input number-selector"
                onClick={() => setIsOpen(!isOpen)}
            >
                {timeZoneFormatted(timeZone)} {/* Показываем текущее выбранное значение */}
            </div>
            {isOpen && (
                <div className="options-flex"> 
                <ul className="options-list number-select">
                    {numbers.map((num) => (
                        <li
                            key={num}
                            className="number-selector-option"
                            onClick={() => handleChange(num)}
                        >
                            {timeZoneFormatted(num)}
                        </li>
                    ))}
                </ul>
                </div>
            )}
        </div>
    );
}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  export const CalendarSelector = ({ type, values_arr, value, setValue }) => {
    const listRef = useRef();
    const [displayedValues, setDisplayedValues] = useState([...values_arr]);
    const init_index = values_arr.includes(value) ? values_arr.indexOf(value) : values_arr.length - 1
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState(init_index);
    const [scrollTimeout, setScrollTimeout] = useState(null);

    useEffect(() => {
        setDisplayedValues([...values_arr]); // Обновляем отображаемые значения при изменении входящего массива
        if (type === "day") {
            if (!values_arr.includes(value)) {
                setValue(values_arr[values_arr.length - 1]); // Установить последнее значение массива
            }
        }
        const initialIndex = values_arr.indexOf(value);
        if (initialIndex !== -1) {
          setCurrentSelectedIndex(initialIndex);
        }

        console.log("initialIndex", initialIndex)
    }, [values_arr]);

    useEffect(() => {
        const scrollToSelectedValue = () => {
          if (listRef.current) {
            const selectedItem = listRef.current.children[currentSelectedIndex];
    
            if (selectedItem) {
              selectedItem.focus();
              selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
          }
        };
    
        const timeoutId = setTimeout(scrollToSelectedValue, 0);
        return () => clearTimeout(timeoutId); // Очистка задержки при размонтировании
      }, [currentSelectedIndex]);

    const valueFormatted = (num) => {
        switch (type) {
            case "day":
            case "year":
            case "hours":
                return String(num);
            case "month":
                return months[num - 1];
            case "minutes":
                return String(num).padStart(2, '0');
            default:
                return String(num);
        }
    };

    const handleClick = (num) => {
        setValue(num); // Устанавливаем значение при клике
        
    };

   const handleWheel = (event) => {
    event.preventDefault();
    console.log("handleWheel currentSelectedIndex", currentSelectedIndex)
    const newIndex = event.deltaY < 0
      ? (currentSelectedIndex === 0 ? displayedValues.length - 1 : currentSelectedIndex - 1)
      : (currentSelectedIndex === displayedValues.length - 1 ? 0 : currentSelectedIndex + 1);

    setCurrentSelectedIndex(newIndex);

    setScrollTimeout(setTimeout(() => setScrollTimeout(null), 150));
  };

  const handleTouchStart = (event) => {
    const touchStartY = event.touches[0].clientY;
    const handleTouchMove = (moveEvent) => {
      const touchEndY = moveEvent.touches[0].clientY;
      if (touchStartY - touchEndY > 30) { // Прокрутка вверх
        const newIndex = currentSelectedIndex === 0 ? displayedValues.length - 1 : currentSelectedIndex - 1;
        setCurrentSelectedIndex(newIndex);
      } else if (touchEndY - touchStartY > 30) { // Прокрутка вниз
        const newIndex = currentSelectedIndex === displayedValues.length - 1 ? 0 : currentSelectedIndex + 1;
        setCurrentSelectedIndex(newIndex);
      }
      document.removeEventListener('touchmove', handleTouchMove); // Убираем слушателя
    };

    document.addEventListener('touchmove', handleTouchMove);
  };


    return (
        <div className={`calendar-selector-container selector-${type}` } >
            <ul className="calendar-options-list" ref={listRef} 
            // onWheel={handleWheel}
            // onTouchStart={handleTouchStart}
            >
                {displayedValues.map((num, index) => (
                    <li
                        key={index}
                        tabIndex={0} 
                        className={`calendar-selector-option ${num === value ? 'selected' : ''}`}
                        onClick={() => handleClick(num)}
                    >
                        {valueFormatted(num)}
                    </li>
                ))}
            </ul>
        </div>
    );
};
//<span style={{width: "40px", height: "40px", border: "2px solid blue", position: "absolute", top: "40px", left: "0"}}></span>