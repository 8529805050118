import './Header.css';
import SwitchToggle from './Header/SwitchToggle';
import logo from '../img/logo.svg'
import { PopupConnectWallet } from './General/PopupWindow';
import { Link } from 'react-router-dom';


const Header = ({ address, onDisconnect, handleConnect, switchDisabled }) => {
    return (
        
        <header className="header">
           
            <Link className="logo" to={"/"}><img src={logo} alt="logo" />Lidum app</Link>
            <SwitchToggle address={address} onDisconnect={onDisconnect} handleConnect={handleConnect} switchDisabled={switchDisabled} />

        </header>
    )
}

export default Header