import './App.css';
import React, { useState, useEffect } from "react";
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Header from './components/Header';
import { Routes, Route, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import Home from './pages/Home'
import ThemeSwitch from './components/ThemeSwitch';
import {Minter} from './pages/Minter';
import { Checker } from './pages/Checker';
import { NftBurning } from './pages/NftBurning';
import { Drop } from './pages/Drop';
import { PopupConnectWallet, PopupContainer } from './components/General/PopupWindow';






const App = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [switchDisabled, setSwitchDisabled] = useState(false); 
  const [popupMessagesMap, setPopupMessagesMap] = useState(new Map())

  const [telegramId, setTelegramId] = useState("123")

  const [username, setUsername] = useState(null)
  const location = useLocation();


  const navigate = useNavigate()
  
  console.log("window.Telegram", window.Telegram)
  
  useEffect(() => {
    
    
    const relativePath = location.pathname;
    console.log("relativePath", relativePath)
    if (relativePath.startsWith('/burn')) {
      return
    }
    console.log("window.Telegram 11111111111111111", window.Telegram)
    // Проверяем доступность Telegram.WebApp
    if (window.Telegram && window.Telegram.WebApp) {
        const initData = window.Telegram.WebApp.initData;
        const params = new URLSearchParams(initData);
        console.log("window.Telegram initData, params", initData, params)
        

      if (!params) {
        navigate('/')
        return
      }
     

      const paramsMap = new Map();
            for (const [key, value] of params.entries()) {
              paramsMap.set(key, value)
            }
            console.log("paramsArray", paramsMap)
      const userParam = paramsMap.get('user')
      if (!userParam) {
        navigate('/')
        return
      }

      const userObject = JSON.parse(userParam)

      if (!userObject) {
        navigate('/')
        return
      }

      console.log("userObject", userObject)
     
      const userId = userObject.id
      setTelegramId(userId.toString())
      setUsername(userObject.username)

      console.log("userId username", userId, userObject.username)

    

        // Получаем start_param
        const param = params.get('start_param');
       

        console.log("window.Telegram param", param)

        if (userId && param) {
          if (param.startsWith('checker-')) {
            const eventId = param.replace('checker-', '');
            if (eventId) {
              navigate(`/checker?event_id=${eventId}`)
              return
                
            } 
          }
          if (param.startsWith('minter-')) {
            const eventId = param.replace('minter-', '');
            if (eventId) {
              navigate(`/minter?event_id=${eventId}`)
              return
                
            } 
          }
        }
       
       

    }
    navigate('/')

}, []);
  
  const [isWalletStatus, setWalletStatus] = useState(null)
   const [chatId, setChatId] = useState(null)


  const [tonConnectUI] = useTonConnectUI();
  

  const wallet = useTonWallet();

  const disconnectHandler = () => {
    if (wallet && !switchDisabled) {
      tonConnectUI.disconnect()
    }
  }

  const connectHandler = async () => {
    if (!wallet && !switchDisabled) {
      await tonConnectUI.openModal()
    }
  }

  const walletAddress = useTonAddress();

  const addPopupMessage = (errorType, errorMessage, errorStatus) => {
    setPopupMessagesMap((prevErrors) => {
        // Создаем новый Map, копируя предыдущие ошибки
        const newErrorsMap = new Map(prevErrors);
        
        // Добавляем новую ошибку или обновляем существующую
        newErrorsMap.set(errorType, {
            status: errorStatus,
            message: errorMessage,
        });
        
        return newErrorsMap; // Возврат нового Map
    });
};

const removePopupMessage = (errorType) => {
  setPopupMessagesMap(prevErrors => {
        const newErrorsMap = new Map(prevErrors);
        newErrorsMap.delete(errorType); // Удаление ошибки по типу
        return newErrorsMap; // Возврат обновленного Map
    });
};

const errorsInMessageMap = () => {
  for (let message of popupMessagesMap.values()) {
    if (message.status === "error") {
      return true
    }
  }
  return false
}

const loadingsInMessageMap = () => {
  for (let message of popupMessagesMap.values()) {
    if (message.status === "loading") {
      return true
    }
  }
  return false
}

const showMessagesAgain = () => {
  const copiedMap = new Map();
    popupMessagesMap.forEach((value, key) => {
        copiedMap.set(key, value);                                    
    });
    setPopupMessagesMap(copiedMap)
}

 
  useEffect(() => {
    const handleConnectionRestored = async () => {
      setWalletStatus("loading")
      const restored = await tonConnectUI.connectionRestored;
      if (restored) {
        setWalletStatus("success");
      } else {
        setWalletStatus("error");
      }
    };

    handleConnectionRestored(); // Вызов функции при монтировании компонента

  }, [tonConnectUI]); // Добавляем tonConnectUI в зависимости
  
 
  
  return (
    
    <>
      <PopupContainer errors={popupMessagesMap} />

   

          <div className="container">
          
          <Header isWalletStatus={isWalletStatus} address={walletAddress ? walletAddress : null} onDisconnect={disconnectHandler} handleConnect={connectHandler} switchDisabled={switchDisabled} /> 
          {/* <ThemeSwitch /> */}
          <div className='main'>
          {/* <PopupConnectWallet status={isWalletStatus} /> */}
          
          <Routes>
            <Route path="/" element={<Home wallet={wallet} searchParams={searchParams} setChatId={setChatId} />} />
            <Route path="/minter" element={<Minter username={username} wallet={wallet} tonConnectUI={tonConnectUI} telegramId={telegramId} isWalletStatus={isWalletStatus} setErrorsMap={setPopupMessagesMap} errorsMap={popupMessagesMap} />}  />
            <Route path="/checker" element={<Checker username={username} wallet={wallet} tonConnectUI={tonConnectUI} setErrorsMap={setPopupMessagesMap} errorsMap={popupMessagesMap} telegramId={telegramId}/>} />
            <Route path="/burn" element={<NftBurning wallet={wallet} tonConnectUI={tonConnectUI} setPopupMessagesMap={setPopupMessagesMap} popupMessagesMap={popupMessagesMap} addPopupMessage={addPopupMessage} removePopupMessage={removePopupMessage} errorsInMessageMap={errorsInMessageMap} showMessagesAgain={showMessagesAgain} loadingsInMessageMap={loadingsInMessageMap} telegramId={telegramId} />} />
            <Route path="/drop" element={<Drop wallet={wallet} tonConnectUI={tonConnectUI}  />}  />
          </Routes>     
          </div>
          

          
          </div>
          </>
  );
}
export default App;

