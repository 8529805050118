import React, { useEffect, useState } from 'react';
import { getCurrentDate } from '../../utils/appUtils';

export const CountdownTimer = ({start_date, end_date, setTimeEnd, nftStatus, setIsCurrentEvent, setIsGetInactive, timeZone}) => {
   
    const endDate = new Date(end_date);

    const startDate = new Date(start_date);

    const [mess, setMess] = useState("")
   

    
    
    
    const [timeLeft, setTimeLeft] = useState({});

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time; // Добавляем ведущий ноль, если цифра одна
    };

    const formatDays = (days) => {
        return days > 1 ? `${days} days ` : `${days} day `
    };

    const calculateTimeLeft = () => {
       
        const now = getCurrentDate(timeZone)
        const endPoint = startDate > now ? startDate : endDate < now ? now : endDate
        const is_stop = startDate > now ? true : endDate < now ? true : false

        if (is_stop) {
            setIsCurrentEvent(false)
            setIsGetInactive(true)
        }
        else {
            setIsCurrentEvent(true)
            
        }

        if (nftStatus) {
            setIsGetInactive(true)
        }

        // if (is_stop || errorsMap.get("nft") || nftStatus) {
        //     setDeactiveFields(true)
        // }
        // else {
        //     setDeactiveFields(false)
        // }

        //setDeactiveFields(is_deactive_fields || errorsMap.get("nft") || nftStatus)
       

        setMess(startDate > now ? "time until the event starts" : endDate < now ? "this event is over" : "time remaining until the event ends")
        if (endDate < now) {
            setTimeEnd(true)
        }
      

        const difference = endPoint - now;

        if (difference <= 0) {
            setTimeLeft({}); // Если время вышло, сбросим состояние
            return;
        }

        const totalHours = Math.floor(difference / (1000 * 60 * 60)); // Всего часов до endPoint
        const days = Math.floor(difference / (1000 * 60 * 60 * 24)); // Полные дни до endPoint
        const hours = totalHours % 24; // Часы, оставшиеся после полного дня
        const minutes = Math.floor((difference / (1000 * 60)) % 60); // Минуты
        const seconds = Math.floor((difference / 1000) % 60); // Секунды

        setTimeLeft({
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds),
            days: days !== 0 ? formatDays(days) : null
        });
    };

    useEffect(() => {
        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer); // Очистка таймера при размонтировании
    }, []);

    return (
        <div className="countdown-timer">
            <p className='label'>{mess}</p>
            {Object.keys(timeLeft).length !== 0 && (
                <p className='timer'>
                {timeLeft.days ? timeLeft.days : ""}{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                </p>
            ) 
            }
        </div>
    );
};