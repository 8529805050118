import { TonConnectButton } from '@tonconnect/ui-react';
import React from 'react';
import { ClipLoader } from 'react-spinners';

export const LoadingIndicator = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <ClipLoader color="#3498db" size={50} />
            <h2>loading...</h2>
        </div>
    );
};


export const formattedDatetime = (datetimeLocalString) => {
    // Преобразование строки в объект Date
    const date = new Date(datetimeLocalString);

    // Получение дня и месяца
    const day = String(date.getDate()).padStart(2, '0');    
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Форматируем результат
    return `${day}.${month}`
}

export const removeElemFromUseStateArr = ({setArr, arr, index_1}) => {
  
    if (index_1 >= arr.length) {
        return
    }
   
    setArr(prevArr => 
        prevArr.filter((_, index) => index !== index_1)
       
      );
      
}   

export const addElemToUseStateArr = ({setArr, elem}) => {
    setArr(prevArr => [
        ...prevArr,
        elem
      ]);
   
}

//Работает плохо
export const replaceElemToUseStateArr = ({setArr, newValue, indexToReplace}) => {
    setArr((prevArr) =>
        prevArr.map((value, index) =>
            index === indexToReplace ? newValue : value // Заменяем только элемент по индексу
        )
    );
  };


  export const getCurrentDate = (timeZone) => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const localTimeZone = -offsetInMinutes / 60;
    const diff = localTimeZone - timeZone
    const diffOffset = diff * 60 * 60 * 1000; 
    const now = new Date(Date.now() - diffOffset);
    return now
  }


  export const createNumbersArr = (min, max) => {
    var arr = []
    for (let i=min; i<=max; i++) {
        arr.push(i)
    }
    return arr

  }