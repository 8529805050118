import { useEffect, useRef, useState } from "react";
import { checkSubscription } from "../../utils/minterUtils";
import channelNotFound from "../../img/channelNotFound.png"
import { getAvatar } from "../../utils/generalConnections";


export const NewSubscriptionsInputBox = ({subscriptions, errorIndices, addSubscription, removeSubscription, replaceSubscription, channelDublicateIndices, channelNotExistIndices, channelInvalidFormatIndices, onFocus, channelAvatars, addOrReplaceChannelAvatar, removeChannelAvatar, lastFocusIndex, setLastFocusIndex}) => {

    const inputRefs = useRef([]);
    const timeoutRefs = useRef([]);
    const [currentFocusIndex, setCurrentFocusIndex] = useState(null)
    
   
    const closeKeyboard = (index) => {
        if (inputRefs[index] && inputRefs[index].current) {
            inputRefs[index].current.blur(); // Убирает фокус с текстового поля
        }
    };
   

    useEffect( () => {
        if (lastFocusIndex && inputRefs.current[lastFocusIndex]) {
            inputRefs.current[lastFocusIndex].focus(); 
        }

    }, [subscriptions])

    const onChange = (e) => {
        
        const index = e.target.dataset.key; // Индекс элемента
        var value = e.target.value.toLowerCase(); // Текущее значение input
        if (value[0] && value[0] !== "@") {
            value = "@" + value
        }   
        const idx = parseInt(index, 10);
        
        if (idx === 0) {
            return
        }
        if (timeoutRefs.current[idx]) {
            clearTimeout(timeoutRefs.current[idx]);
          }
      
          // Установка нового таймера на 500 мс
          timeoutRefs.current[idx] = setTimeout(async () => {
            await handleInputEnd(value, idx);
          }, 300);

        if (idx === subscriptions.length) {
            if (value !== "") {
                addSubscription(value)

               
            // setTimeout(() => {
            //     if (inputRefs.current[idx]) {
            //         inputRefs.current[idx].focus(); 
            //     }
            // }, 0);
            }
            return           
        }

        if (value === "") {
            removeSubscription(idx);
            return         
        }        
        replaceSubscription(value, idx); 
        console.log("subscription", subscriptions[idx])

        
    };


    const addNewStyle = (id) => {
        document.getElementById(id).classList.add("new-input")   
    }

    const removeNewStyle = (id) => {
        document.getElementById(id).classList.remove("new-input")   
    }
   
   const handleInputEnd = async (subscription) => {

    if (getAvatarUrl(subscription)) {
        return
    }
    
    const avatarUrl = await getAvatar(subscription);
    if (avatarUrl) {
        addOrReplaceChannelAvatar(subscription, avatarUrl)
    }
    clearAvatarsChannel()
   }

   const clearAvatarsChannel = () => {
    for (let subscription of channelAvatars.keys()) {
        if (!subscriptions.includes(subscription)) {
            removeChannelAvatar(subscription)
        }
      }
   }

    const getMessage = (index) => {
        if (channelInvalidFormatIndices.includes(index)) {
            return "invalid format"
        }
        if (channelNotExistIndices.includes(index)) {
            return "channel is not exist"
        }
        if (channelDublicateIndices.includes(index)) {
            return "repeating channel"
        }
    }
    
    const getAvatarUrl = (subscription) => {
        if (channelAvatars.get(subscription)) {
            return channelAvatars.get(subscription)
        }
        return null
    }


    return (
       
        <div className="subscriptions-input-box">
        
            <span className="channel-with-avatar-container">
            
            <span className="form-input default-subscription channel-input channel-with-avatar">{subscriptions[0]}</span>
            <img className="channel-avatar" src={getAvatarUrl("@lidumapp")} alt={"@lidumapp avatar"} width={41} height={41}/>
            </span>
            {subscriptions.slice(1).map((subscription, index) => (
                <>
                <div className="channel-with-avatar-container">
                <input
                    className={`form-input channel-input ${errorIndices.includes(index + 1) ? 'error' : "" } channel-with-avatar`}
                    type="text"
                    id={`subscription_${index + 1}`}
                    data-key={index + 1}
                    key={index + 1}
                    onChange={onChange}
                    onFocus={() => {
                        onFocus(index + 1)
                        setCurrentFocusIndex(index + 1)
                        setLastFocusIndex(index+1)
                    }}
                    name={`subscription_${index + 1}`}
                    value={subscriptions[index+1]} 
                    required
                    maxLength={256}
                    ref={el => inputRefs.current[index + 1] = el}
                    onBlur={() => setCurrentFocusIndex(null)}
                />
                <img className="channel-avatar" src={getAvatarUrl(subscription) ? getAvatarUrl(subscription) : channelNotFound} alt={subscription + " avatar"} />
                {currentFocusIndex === index + 1 && (
                        <span className={`close-keyboard ${errorIndices.includes(index + 1) ? 'error' : "" }`}>✖</span>
                    )
                }
               
                </div>
                {errorIndices.includes(index + 1) && (
                     <p className="error-message">{getMessage(index + 1)}</p>   
                )}
                </>
                
            ))}
            <input
                className={`form-input channel-input new-input`}
                type="text"
                id={`subscription_${subscriptions.length}`}
                key={subscriptions.length}
                data-key={subscriptions.length}
                onChange={onChange}
                name={`subscription_${subscriptions.length}`}
                defaultValue=""
                maxLength={256}
                onFocus={() => {
                    removeNewStyle(`subscription_${subscriptions.length}`)
                    setCurrentFocusIndex(subscriptions.length)
                    setLastFocusIndex(subscriptions.length)
                    }}

                onBlur={(e) => {
                    addNewStyle(`subscription_${subscriptions.length}`)    
                    setCurrentFocusIndex(null)             

                }}
                ref={el => inputRefs.current[subscriptions.length] = el}
            />
        </div>
    );
};