import { useNavigate } from "react-router-dom";

export const WarningModal = ({isModalOpen, setIsModalOpen }) => {

  
    const navigate = useNavigate()
    const onConfirm = () => {        
        navigate(`/`)
      };

      const handleOverlayClick = (event) => {
        // Проверяем, был ли клик по оверлею
        if (event.target === event.currentTarget) {
         setIsModalOpen(false)
        }
      };


    return (
        <>
        {isModalOpen && (
            <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-window">
                <p className="modal-message">if you leave this page, the entered data will not be saved</p>
                <div className="modal-buttons-container">                
                <button className="modal-button" onClick={() => setIsModalOpen(false)}>stay</button>
                <button className="modal-button leave" onClick={onConfirm} >leave</button>
                </div>
            </div>
            </div>
            )        
        }
        </>
      );
}

export const LeaveCheckerWindow = ({isOpen}) => {

  console.log("isOpen", isOpen)

  
  const onClose = () => {        
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    }
    else {
      window.close();
    }
  };


  return (
    <>
      
    {isOpen && (

      <div className="modal-overlay blurred">
      <div className="modal-window">
          <p className="modal-message">Congratulations, the NFT has been sent to your wallet!</p>            
          <button className="modal-button" onClick={onClose} >close</button>            
      </div>
    </div>

    )}  


    
    </>
  );
}

export const ModalWindow = ({isOpen, message, onClick}) => {

 

  return (
    <>
      
    {isOpen && (

      <div className="modal-overlay blurred">
      <div className="modal-window">
          <p className="modal-message">{message}</p>            
          <button className="modal-button" onClick={onClick} >close</button>            
      </div>
    </div>

    )}  


    
    </>
  );

}

const onClose = () => {        
  if (window.Telegram && window.Telegram.WebApp) {
    window.Telegram.WebApp.close();
  }
  else {
    window.close();
  }
};

export const MakePostResultWindow = ({isOpen}) => {

 


  if (!isOpen) {
    return
  }

  return (
    <>
      <ModalWindow isOpen={isOpen} message={"The post with the giveaway has been successfully created!"} onClick={onClose} />
    </>
  )

}

export const TimeEndOverlay = ({timeEnd}) => {
  if (!timeEnd) {
      return null
  }
  return (
    <div className="overlay-window">
      <p className="overlay-window-message">this event is over</p>
    </div>
  );
};

